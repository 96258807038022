
























import { defineComponent, reactive } from 'vue-demi';
import { partsDb } from '../PartsDb';
import { Part } from '@project/shared';
import { useRouter } from '@/router';
import { useStore } from '@/store';

export default defineComponent({
  components: {
    Viewer: () => import('@/viewer/Viewer.vue'),
  },

  setup() {
    const router = useRouter();
    const viewerFiles = reactive({
      modelUrl: '',
      hdrUrl: '',
    });

    (async () => {
      const [model, texture] = await Promise.all([
        partsDb.getFile('models/rovjet.glb'),
        partsDb.getFile('textures/beach_probe.hdr'),
      ]);

      if (!model) {
        return;
      }

      viewerFiles.modelUrl = URL.createObjectURL(model);
      viewerFiles.hdrUrl = URL.createObjectURL(texture);
    })().catch((err) => {
      console.error(err);
    });

    function onSelectPart(part: Part) {
      router.push({
        name: 'parts',
        params: {
          codeAmos: part.codeAmos,
        },
      });
    }

    function onClickDrawningsParts() {
      router.push({
        name: 'parts',
        params: {
          codeAmos: 'P00-221-78',
        },
      });
    }

    const store = useStore();
    function onSearchPart() {
      store.state.showSearchPart = true;
    }

    return {
      viewerFiles,
      onSelectPart,
      onClickDrawningsParts,
      onSearchPart,
    };
  },
});
